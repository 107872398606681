import React, {useEffect, useState, useCallback} from 'react'
import { ConfigProvider, Table } from "antd";
import {searchSealUsageRecord} from "../../../request/esign";
import dayjs from "dayjs";
import SearchForm from "./components/searchForm";
import TableRowWrapper from "./components/TableRowWrapper";
import zh_CN from "antd/es/locale/zh_CN";
import TableRowImage from "./components/TableRowImage";
import './index.less'
import {WARD_TYPE} from "./constants";
import {RequestQueue} from "../utils/requestQueue";
import {ERROR_ESIGN_RECORD_PERMISSION_FAIL} from "../../../request/error_container";
import {errorMessage} from "../../components/Message";
import NetError from "../EsignOrgDoc/NetError";

const requestQueue = new RequestQueue({ maxConcurrentRequests: 3, pause: 1500 });

function SealUsageRecord() {

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState({
        pageSize: 10,
        pageNum: 1
    });
    const [loadError, setLoadError] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [total, setTotal] = useState(0);
    const [tableHeight, setTableHeight] = useState('calc(100vh - 380px)');

    const onPaginationChange = (pageNum, pageSize) => {
        const params = Object.assign({}, searchData, { pageNum, pageSize })
        setSearchData(params)
    }

    const pagination = {
        total: 0,
        pageCount: 0,
        showSizeChanger: true,
        onChange: onPaginationChange,
        pageSizeOptions: ['10', '30', '50', '100'],
    };

    const fetchTableList = useCallback(() => {
        setLoading(true);
        setLoadError(false);
        requestQueue.clearQueue();
        searchSealUsageRecord(searchData).then(data => {
            if (searchData.isExport) {
                if (data && data.total < 1) {
                    errorMessage('没有查询到数据');
                }
                return;
            }
            setTaskList(data.list)
            setTotal(data.total)
        }) .catch(err => {
            setLoadError(true);
            if (err === ERROR_ESIGN_RECORD_PERMISSION_FAIL) {
                errorMessage('您没有权限查看该记录')
            }
            setTaskList([]);
            setTotal(0);
        })
        .finally(() => {
            setLoading(false)
        })
    }, [searchData])

    const calcContainerTop = () => {
        let top = 0;
        const containerEle = document.querySelector('#SealUsageRecordSearchForm');
        if (containerEle) {
            top = containerEle.getBoundingClientRect().top;
        }
        return top;
    }

    useEffect(() => {
        const groupContainerTop = calcContainerTop();
        const searchFormEle = document.querySelector('#SealUsageRecordSearchForm');
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const targetHeight = entry.contentRect.height;
                handleHeightChange(targetHeight);
            }
        });
        observer.observe(searchFormEle);
        function handleHeightChange(contentHeight) {
            const paddingHeight = 15 * 2;
            const marginBottomHeight = 15;
            const searchFormCurrentHeight = contentHeight + paddingHeight + marginBottomHeight;
            const tablePaddingHeight = 15 * 2;
            const tableHeaderHeight = 43;
            const tablePagingHeight = 32;
            const gapHeight = searchFormCurrentHeight + groupContainerTop + tablePaddingHeight + tableHeaderHeight + tablePagingHeight + 15;
            setTableHeight(`calc(100vh - ${gapHeight}px)`)
        }
        return () => {
            observer.disconnect();
        }
    }, [])

    useEffect(() => {
        fetchTableList()
    }, [searchData, fetchTableList])

    const tableEmptyRender = () => <span>暂无数据</span>

    const getColumns = () => {
        let columns = [
            {
                title: '印章',
                dataIndex: 'seal_id',
                key: 'seal_id',
                ellipsis: true,
                width: '10%',
                render: (sealId, record) => {
                    return <TableRowImage
                        sealLogId={record.id}
                        updateKey={`${sealId}_${Date.now()}`}
                        requestQueue={requestQueue}
                        isLastItem={taskList && taskList[taskList.length - 1]?.id === record?.id}
                    />
                }
            },
            {
                title: '印章名称',
                dataIndex: 'seal_name',
                key: 'seal_name',
                ellipsis: true,
                width: '15%',
                render: (val) => {
                    return (
                        <TableRowWrapper val={val ?? '-'} placement='bottom'>
                            <span>{val ?? '-'}</span>
                        </TableRowWrapper>
                    )
                }
            },
            {
                title: '印章类型',
                dataIndex: 'ward_type',
                key: 'ward_type',
                ellipsis: true,
                width: '110px',
                render: (val, record) => {
                    let text;
                    if (val === 0) {
                        if (record.seal_type === 0) {
                            text = '个人章（对外签署）'
                        } else {
                            text = '企业章'
                        }
                    } else {
                        text = '个人章（对内签署）'
                    }
                    return <TableRowWrapper val={text}>
                            <span>{text}</span>
                         </TableRowWrapper>
                },
            },
            {
                title: '任务名称',
                dataIndex: 'sign_process_name',
                key: 'sign_process_name',
                ellipsis: true,
                width: '20%',
                render: (val) => {
                    return (
                    <TableRowWrapper val={val} placement='bottom'>
                        <span>{val}</span>
                    </TableRowWrapper>
                    )
                }
            },
            {
                title: '用印人员',
                dataIndex: 'recipient_name',
                key: 'recipient_name',
                ellipsis: true,
                width: '10%',
                render: (val) => {
                    return (
                        <TableRowWrapper val={val}>
                            <span>{val}</span>
                        </TableRowWrapper>
                    )
                }
            },
            {
                title: '用印时间',
                dataIndex: 'create_time',
                key: 'create_time',
                ellipsis: true,
                width: '110px',
                render: (val) => {
                    let date = dayjs(val * 1000).format('YYYY-MM-DD HH:mm:ss');
                    return (
                        <TableRowWrapper val={date}>
                            <span>{dayjs(val * 1000).format('YYYY-MM-DD')}</span>
                        </TableRowWrapper>
                    )
                },
            }
        ]
        if(searchData.wardType === WARD_TYPE.inSign) {
            const deleteColumnKeys = ['ward_type', 'seal_name']
            columns = columns.filter(column => !deleteColumnKeys.includes(column.key))
        }
        return columns;
    }

    const onSearchFormDataChange = (searchFormData) => {
        const params = Object.assign({}, searchData, searchFormData, { pageNum: 1 })
        setSearchData(params)
    }


    return (
        <div className='seal-usage-record'>
            <ConfigProvider locale={zh_CN}  renderEmpty={tableEmptyRender}>
                <SearchForm onSearchFormDataChange={onSearchFormDataChange} loading={loading}/>
                {
                    loadError && <NetError handleClick={fetchTableList}></NetError>
                }
                {
                    !loadError &&
                    <Table
                        className="seal-usage-record-table"
                        loading={loading}
                        columns={getColumns()}
                        scroll={{ y: tableHeight }}
                        dataSource={taskList}
                        rowKey={record => record.id}
                        pagination={{
                            ...pagination,
                            current: searchData.pageNum,
                            defaultPageSize: searchData.pageSize,
                            showQuickJumper: true,
                            total: total,
                            showTotal: (total) => `总共 ${total} 条`,
                        }}
                    />
                }
            </ConfigProvider>
        </div>
    )
}

export default SealUsageRecord;