import React, { Component } from 'react';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import EsignTable from './EsignTable/EsignTable';
import './EsignDoc.less';
import {getStore} from "../../../store";
import {getNewSubscription, getSignProcessListTotal} from "../../../request/esign";
import {getOtelSpanName, getQueryVariable} from "../service";
import {generateQrCode} from "../utils/util";
import phantom_icon from '@/assets/images/phantom_icon.svg';
import {showShareOnlineDocDialog} from "../Model";
import {generateShareDocBeautyQrCode} from "./utils";
import OtelService from "../../../utils/otel/service";
import EsignDocSearchForm from "./SearchForm";


const otelService = new OtelService();

// import { eSignChinaApi } from '@/api';
// import moment from 'moment';
// import {eSignChinaService } from '@/store/services/index';

export default class EsignDoc extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      loading: false,
      searchWord: '',
      showMyEsign: false,
      role: 0,
      tab: '1',
      status:null,
      subscription: {
        personExternal: null,
        orgExternal: null,
        orgInternal: null,
      },
      listTotal:{
        complete: '',
        myCreate: '',
        waitForMe: '',
        waitForOther: ''
      },
      expandedSearchParams: {},
      tableHeight: 'calc(100vh - 380px)'
    };
    this.searchFormEleObserver = null;
  }
  pagination = {
    total: 0,
    pageCount: 0,
    showSizeChanger: true,
    onShowSizeChange: this.onShowSizeChange,
    // hideOnSinglePage: true,
    onChange: this.pageChange,
    pageSizeOptions: ['10', '30', '50', '100'],
  };
  async componentDidMount() {
    this.handleSearchFormObserver = this.handleSearchFormObserver.bind(this);
    this.getSubscription();
    const listTotal = await getSignProcessListTotal();
    this.setState({listTotal})
    this.checkAndOpenShareDocDialog()
    this.handleSearchFormObserver();
  }

  componentWillUnmount() {
    this.searchFormEleObserver && this.searchFormEleObserver.disconnect();
  }

  calcContainerTop() {
    let top = 0;
    const containerEle = document.querySelector('#EsignDocSearchForm');
    if (containerEle) {
      top = containerEle.getBoundingClientRect().top;
    }
    return top;
  }

  handleSearchFormObserver() {
    const groupContainerTop = this.calcContainerTop();
    const searchFormEle = document.querySelector('#EsignDocSearchForm');
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const targetHeight = entry.contentRect.height;
        handleHeightChange(targetHeight);
      }
    });
    observer.observe(searchFormEle);
    this.searchFormEleObserver = observer;
    const handleHeightChange = (contentHeight) => {
      const fun = () => {
        const paddingHeight = 15 * 2;
        const marginBottomHeight = 15;
        const searchFormCurrentHeight = contentHeight + paddingHeight + marginBottomHeight;
        const tablePaddingHeight = 15 * 2;
        const tableHeaderHeight = 43;
        const tablePagingHeight = 40;
        const gapHeight = searchFormCurrentHeight + groupContainerTop + tablePaddingHeight + tableHeaderHeight + tablePagingHeight + 45;
        this.setState({
          tableHeight: `calc(100vh - ${gapHeight}px)`
        })
      }
      window.requestAnimationFrame(fun);
    }
  }

  checkAndOpenShareDocDialog() {
    const share_qr_code_data = getQueryVariable('share_qr_code_data');
    if (share_qr_code_data) {
      const share_qr_code_data_json = JSON.parse(share_qr_code_data);
      const { processId, taskName, creatorName, names } = share_qr_code_data_json;
      let config = getStore('configUrls')
      let newEsignCNpc = config.newEsignCNpc;
      const enterpriseId = getStore('userInfo').enterpriseId;
      let url = enterpriseId?`${newEsignCNpc}?id=${processId}&enterpriseId=${enterpriseId}`:`${newEsignCNpc}?id=${processId}`;
      generateQrCode(url, phantom_icon).then(dataUri => {
        return generateShareDocBeautyQrCode(dataUri, { taskName, creator_name: creatorName, receiptsNames: names.join('；') });
      }).then(dataUri => {
        const key = 'base64,'
        const i = dataUri.indexOf(key);
        const base64 = dataUri.slice(i + key.length);
        const params = {
          title: '分享',
          shareUrl: url,
          qrCodeBase64: base64,
          qrCodeDataUri: dataUri,
          slogan: '可将合同分享给已参与当前合同流程的人员，快速完成或查看合同任务',
          copyBtnText: '复制链接',
          qrCodePrimaryTitle: `由${creatorName}分享的二维码`,
          qrCodeDesc: '请使用微信扫码',
          signReceiptsDesc: `合同参与人：${names.join('；')}`,
          qrCodeSecondTitle: taskName,
          okBtnText: '保存二维码',
          cancelBtnText: '取消',
          taskName: taskName,
        }
        setTimeout(() => {
          showShareOnlineDocDialog(params);
        }, 1000)
      })
    }
  }

  isCurrentSigner = (list) => {
    const { cUserID, userEmail, userMobile } = getStore('userInfo')
    list.some((item) => {
      return item.phone === userMobile || item.email === userEmail || item.foxit_user_id === cUserID;
    });
  };

  searchChange = (e) => {
    e.persist();
    this.setState({ searchWord: e.target.value }, () => {
      if (e.target.value.length === 0) {
        this.updateList()
      }
    });
  };
  onClickSearchIcon = () => {
    this.updateList()
  };
  updateList = ()=>{
    const { tab,role, status, searchWord } = this.state;
    this.tableRef.searchWordList({ role, status, searchWord })

   const t = setTimeout(() => {
      this.tableRef && this.tableRef.refresh && this.tableRef.refresh();
      clearTimeout(t);
    }, 300)
  }
  onChange = (e) => {
    let role ,status;
    switch (e){
      case '0':
        role=0;
        status=null
        break;
      case '1':
        role=1;
        status=null
        break;
      case '2':
        role=2;
        status=null
        break;
      case '3':
        role=3;
        status=null
        break;
      case '4':
        role=4;
        status=2
        break;
      case '5':
        role=5;
        status=null
        break;
      default:
        break;
    }
    this.setState({
      role,
      status,
      tab:e
    },()=>{
      this.updateList()
    })
  };

  getSubscription = async () => {
    // wardType 1:对内；0：对外
    const userInfo = getStore('userInfo');
    const enterpriseId = userInfo.enterpriseId;
    let param = {
      wardType: 0,
    };
    if (enterpriseId) {
      param.tenantId = enterpriseId;
    }
    const subscription = await getNewSubscription(param);
    otelService.startCommonSpan(getOtelSpanName('request-signRecord-checkSubscription')).then(({end}) => {
      end && end();
    });
    this.setState({
      subscription,
    });
  };

  logoClick = () => {
    const { showMyEsign } = this.state;
    this.setState({
      showMyEsign: !showMyEsign,
    });
  };
  contactSales = () => {
    window.open('https://www.foxitsoftware.cn/esign#sales');
  };
  isExpired = (subscription) => {
    const { personExternal, orgExternal, orgInternal, serverTime } = subscription;
    if (personExternal && personExternal.expreTime) {
      if (serverTime > personExternal.expreTime) {
        return true;
      }
    }
    if (orgExternal && orgExternal.expreTime) {
      if (serverTime > orgExternal.expreTime) {
        return true;
      }
    }
    if (orgInternal && orgInternal.expreTime) {
      if (serverTime > orgInternal.expreTime) {
        return true;
      }
    }
    if (personExternal && personExternal.docNumber === 0) {
        return true;
    }

    return false;
  };

  updateExpandedSearchParams = (expandedSearchParams) => {
    this.setState({ expandedSearchParams });
  }

  render() {
    const { searchWord, listTotal, role, expandedSearchParams, tableHeight } = this.state;
    return (
      <div className="EsignDoc">
        <EsignDocSearchForm
            role={role}
            listTotal={listTotal}
            onChange={this.onChange}
            onClickSearchIcon={this.onClickSearchIcon}
            updateExpandedSearchParams={this.updateExpandedSearchParams}
        />

        <EsignTable
            ref={ref => this.tableRef = ref}
            role={role + ""}
            searchWord={searchWord}
            expandedSearchParams={expandedSearchParams}
            tableHeight={tableHeight}
            onChange={this.onChange}
            {...this.props}
        />
      </div>
    );
  }
}
