import {Button, Form, Input, DatePicker, ConfigProvider, Select} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import './index.less'
import zh_CN from "antd/es/locale/zh_CN";
import {SIGN_STATUS, SIGN_TYPE, WARD_TYPE, FREE_RECT_ITEM} from "../../SealUsageRecord/constants";
import {getStore} from "../../../../store";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {ORG_PERMISSION} from "../../service";

const { RangePicker } = DatePicker;

const initFieldDisabledInfo = {
    wardType: false,
    signType: false,
    completeTimeRange: false,
    status: false
}

const initFormData = {
    processName: '',
    creator: '',
    createTimeRange: [],
    wardType: 0,
    signType: -1,
    freeRectItem: -1,
    recipient: '',
    completeTimeRange: [],
    status: -1,
}


function EsignDocSearchForm(props) {
    const {role, listTotal, onClickSearchIcon, updateExpandedSearchParams, permission} = props;

    const subscriptionData = getStore('plugin').subscriptionData;
    const isOnlyInSignSubscription = subscriptionData.orgInternal?.cert && (!subscriptionData.orgExternal?.cert && !subscriptionData.personExternal?.cert)

    const [searchMore, setSearchMore] = useState(false);

    const [statisticInfo, setStatisticInfo] = useState(null);

    const [fieldDisabledInfo, setFieldDisabledInfo] = useState(initFieldDisabledInfo);

    const [form] = Form.useForm();

    const handleSearchMore = () => {
        setSearchMore(!searchMore)
    }

    const onFormValuesChange = useCallback(() => {
        const data = form.getFieldsValue();
        const params = formatFormData(data);
        updateExpandedSearchParams(params);
    }, [form, updateExpandedSearchParams])

    const formatFormData = (data) => {
        const {createTimeRange, completeTimeRange} = data;
        const newData = {...data};
        if (createTimeRange && createTimeRange.length) {
            newData.createTimeStart = dayjs(createTimeRange[0]).startOf('day').unix();
            newData.createTimeEnd = dayjs(createTimeRange[1]).endOf('day').unix();
        }
        delete newData.createTimeRange;
        if (completeTimeRange && completeTimeRange.length) {
            newData.completeTimeStart = dayjs(completeTimeRange[0]).startOf('day').unix();
            newData.completeTimeEnd = dayjs(completeTimeRange[1]).endOf('day').unix();
        }
        delete newData.completeTimeRange;
        Object.keys(newData).forEach(key => {
            if (newData[key] === -1 || newData[key] === '') {
                delete newData[key]
            }
        })
        return newData;
    }

    const onSubmit = () => {
        onClickSearchIcon();
    }

    const updateDefaultFormData = useCallback((role, formSetFieldValue) => {
        let val = role;
        const fieldDisabledInfoTemp = {};
        if ([2,3].includes(+val)) {
            fieldDisabledInfoTemp.completeTimeRange = true;
            fieldDisabledInfoTemp.status = true;
            formSetFieldValue('completeTimeRange', null);
            formSetFieldValue('status', SIGN_STATUS.processing);
        } else {
            fieldDisabledInfoTemp.completeTimeRange = false;
            fieldDisabledInfoTemp.status = false;
        }
        if([1,2,3].includes(+val)) {
            fieldDisabledInfoTemp.signType = true;
            formSetFieldValue('signType', SIGN_TYPE.flowSign);
        } else if(+val === 5) {
            fieldDisabledInfoTemp.signType = true;
            fieldDisabledInfoTemp.status = true;
            formSetFieldValue('signType', SIGN_TYPE.flowSign);
            formSetFieldValue('status', SIGN_STATUS.finished);
        } else {
            fieldDisabledInfoTemp.signType = false;
        }
        if (+val === 4) {
            formSetFieldValue('status', SIGN_STATUS.all);
            formSetFieldValue('signType', SIGN_TYPE.all);
        }
        if (+val === 1) {
            formSetFieldValue('status', SIGN_STATUS.all);
        }
        if (+val === 0) {
            formSetFieldValue('status', SIGN_STATUS.all);
            formSetFieldValue('signType', SIGN_TYPE.all);
            // formSetFieldValue('wardType', WARD_TYPE.all);
            formSetFieldValue('freeRectItem', FREE_RECT_ITEM.all);
        }
        setFieldDisabledInfo(prevState => ({...prevState, ...fieldDisabledInfoTemp}));
        onFormValuesChange();
    }, [setFieldDisabledInfo, onFormValuesChange])

    const onResetFormData = () => {
        form.setFieldsValue({
            ...initFormData,
            wardType: isOnlyInSignSubscription ? WARD_TYPE.inSign : WARD_TYPE.outSign
        })
        updateDefaultFormData(role, form.setFieldValue);
        onFormValuesChange();
        setTimeout(() => {
            onClickSearchIcon();
        },0);
    }

    useEffect(() => {
        const isOnlyInPermission = permission.inOrgPermission && permission.inOrgPermission.includes(ORG_PERMISSION.SIGN_RECORD) && (!permission.outOrgPermission || !permission.outOrgPermission.includes(ORG_PERMISSION.SIGN_RECORD));
        if (isOnlyInPermission) {
            form.setFieldsValue({
                wardType: WARD_TYPE.inSign
            })
        } else {
            form.setFieldsValue({
                wardType: WARD_TYPE.outSign
            })
        }
        onFormValuesChange();
    }, [form, permission, onFormValuesChange])

    useEffect(() => {
        updateDefaultFormData(role, form.setFieldValue);
    }, [role, form.setFieldValue, updateDefaultFormData])

    useEffect(() => {
        const { waitForMe, waitForOther } = listTotal;
        if (waitForMe || waitForOther) {
            if (!statisticInfo) {
                const info = {
                    '2': listTotal.waitForMe || "",
                    '3': listTotal.waitForOther || "",
                }
                setStatisticInfo(info)
            }
        }
    }, [listTotal, statisticInfo])

    return (
        <ConfigProvider locale={zh_CN}>
            <Form
                form={form}
                id='EsignDocSearchForm'
                className={searchMore ? 'search-form' : 'search-form search-form-shrink'}
                initialValues={initFormData}
                onValuesChange={onFormValuesChange}
                onFinish={onSubmit}
            >
                <div className='search-row'>
                    <Form.Item
                        label="任务名称"
                        name="processName"
                        style={{ marginBottom: 0 }}
                    >
                        <Input
                            // onChange={searchChange}
                            onPressEnter={onClickSearchIcon} // todo
                            allowClear
                            placeholder='请输入任务名称'
                        />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <div className='search-form-btn-group'>
                            &nbsp;&nbsp;
                            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#A236B2', color: "#FFF", borderColor: "inherit"}}>搜索</Button>&nbsp;&nbsp;
                            <Button onClick={onResetFormData}>重置</Button>&nbsp;&nbsp;
                            <div className={ searchMore ?  'btn-more' : 'btn-more-close'} onClick={handleSearchMore}><span className='btn-more-icon1'></span><span className='btn-more-icon2'></span></div>
                        </div>
                    </Form.Item>
                </div>
                <div className='more-search-panel' style={{ overflowX: 'auto'}}>
                    <div style={{ minWidth: '1020px' }}>
                        <div className='search-row'>
                            <Form.Item
                                label="发起人"
                                name="creator"
                                className='search-form-item'
                            >
                                <Input
                                    allowClear
                                    placeholder='姓名/手机号/邮箱'
                                />
                            </Form.Item>
                            <Form.Item
                                label="发起时间"
                                name="createTimeRange"
                                className='search-form-item search-form-item-large'
                            >
                                <RangePicker/>
                            </Form.Item>
                            <Form.Item
                                label='签署场景' // todo 受控
                                name='wardType'
                                className='search-form-item'
                            >
                                <Select
                                    placeholder="请选择签署场景"
                                    options={[
                                        {
                                            value: WARD_TYPE.outSign,
                                            label: '对外签署',
                                        },
                                        {
                                            value: WARD_TYPE.inSign,
                                            label: '对内签署',
                                        }
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                label='签署方式' // todo 受控
                                name='freeRectItem'
                                className='search-form-item'
                            >
                                <Select
                                    placeholder="请选择签署方式"
                                    options={[
                                        {
                                            value: FREE_RECT_ITEM.all,
                                            label: '全部',
                                        },
                                        {
                                            value: FREE_RECT_ITEM.specify,
                                            label: '指定签署区域',
                                        },
                                        {
                                            value: FREE_RECT_ITEM.unspecify,
                                            label: '不指定签署区域',
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className='search-row'>
                            <Form.Item
                                label="参与人"
                                name="recipient"
                                className='search-form-item'
                            >
                                <Input
                                    onPressEnter={onClickSearchIcon}
                                    allowClear
                                    placeholder='姓名/手机号/邮箱'
                                />
                            </Form.Item>
                            <Form.Item
                                label="结束时间"
                                name="completeTimeRange"
                                className='search-form-item search-form-item-large'
                            >
                                <RangePicker/>
                            </Form.Item>
                            <Form.Item
                                label='签署类型'  // todo 受控
                                name="signType"
                                className='search-form-item'
                            >
                                <Select
                                    placeholder="请选择签署类型"
                                    disabled={fieldDisabledInfo.signType}
                                    options={[
                                        {
                                            value: SIGN_TYPE.all,
                                            label: '全部',
                                        },
                                        {
                                            value: SIGN_TYPE.flowSign,
                                            label: '邀请签署',
                                        },
                                        {
                                            value: SIGN_TYPE.selfSign,
                                            label: '个人签署',
                                        }
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                label='状态'
                                name='status'
                                className='search-form-item'
                            >
                                <Select
                                    placeholder="请选择签署状态"
                                    disabled={fieldDisabledInfo.status}
                                    options={[
                                        {
                                            value: SIGN_STATUS.all,
                                            label: '全部',
                                        },
                                        {
                                            value: SIGN_STATUS.finished,
                                            label: '已完成',
                                        },
                                        {
                                            value: SIGN_STATUS.processing,
                                            label: '签署中',
                                            disabled: +role === 4
                                        },
                                        {
                                            value: SIGN_STATUS.canceled,
                                            label: '已撤销',
                                        },
                                        {
                                            value: SIGN_STATUS.expired,
                                            label: '已过期',
                                        },
                                        {
                                            value: SIGN_STATUS.rejected,
                                            label: '已拒签',
                                        },{
                                            value: SIGN_STATUS.invaliding,
                                            label: '作废中',
                                        },
                                        {
                                            value: SIGN_STATUS.invalid,
                                            label: '已作废',
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </ConfigProvider>
    )
}

export default observer(EsignDocSearchForm);