import React, { Fragment, useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import {
    AutoComplete,
    Avatar,
    Button,
    Col,
    ConfigProvider,
    Divider,
    Input,
    Modal,
    Row,
    Space,
} from 'antd';
import locale from 'antd/es/locale/zh_CN';
import styles from './index.module.less';
import { DeleteOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import {searchFlowManFn} from "../../../../service";
import {searchEmailForSign} from "../../../../../../request/user";
import {getProcessCCInfo, updateProcessCCInfo} from "../../../../../../request/esign";
import {errorMessage, successMessage} from "../../../../../components/Message";

function generateId(){
    return Math.random().toString(36).slice(2);
}

function getUserMobile(mobile) {
    if (!mobile) {
        return '';
    }
    if (mobile.indexOf('+86') === 0 && mobile.length === 14) {
        mobile = mobile.substr(3, 11);
    }
    if (mobile.length === 11) {
        return mobile;
    }
    return '';
}

let debounceTimer = null;
function debounce(fn, delay) {
    clearTimeout(debounceTimer);
    return function() {
        debounceTimer = setTimeout(fn, delay);
    };
}

const UserType = {person: 1, org: 2};
async function getOptions(str, isOut = true, type = 0) {
    const params = {
        page: 1,
        pageSize: 20,
    };
    let res;
    if (isOut) {
        switch (type) {
            case 1:
                params.type = 'contact';
                break;
            case 2:
                params.type = 'company';
                break;
            default:
                params.type = 'name';
        }
        params.keyword = str;
        res = await searchFlowManFn(params);
    } else {
        if (type === 1) {
            params.useremailOrMobile = str;
        } else {
            params.username = str;
        }
        res = await searchEmailForSign(params);
    }

    if (!res) {
        return [];
    }
    const list = !isOut ? res.users : res;

    if (!list || list.length < 1) {
        return [];
    }

    return list.map((item) => {
        const data = {};
        let isOnlyCompany = false;
        if (!isOut) {
            data.email = item.userEmail || '';
            data.name = item.userName || '';
            data.mobile = getUserMobile(item.mobile);
        } else {
            isOnlyCompany = type === 2 && !item.company;
            if (isOnlyCompany) {
                data.email = '';
                data.mobile = '';
                data.name = '';
                data.company = item.name;
            } else {
                data.email = item.email;
                data.mobile = item.phone;
                data.name = item.name;
                data.company = item.company;
            }
        }

        let contact = type === 1 && /^\d+$/.test(str) && data.mobile ? data.mobile : data.email;
        data.contact = contact || data.email || data.mobile;
        return {
            value: JSON.stringify(data),
            label: <OptionLabel item={data} type={isOnlyCompany ? 3 : type} />,
        };
    });
}
function OptionLabel({ item, type = 0 }) {
    const { contact = '', name = '', company = '' } = item;
    let upStr = '';
    let downStr;
    let title;
    if (type === 3) { // 企业查
        downStr = company;
        title = company;
    } else {
        if (company) {
            downStr = company;
            upStr = contact;
        } else {
            downStr = contact;
            type = 2;
        }
        title = name;
        if (contact) {
            title += '\n' + contact;
        }
        if (company) {
            title += '\n' + company;
        }
    }
    return (
        <div title={title} className={styles.item}>
            {type !== 3 && <div className={styles.top}>
                <div className={type === 0 ? styles.match : ''}>{name}&emsp;</div>
                <div className={styles.elli + ' ' + (type === 1 ? styles.match : '')}>{upStr}</div>
            </div>}
            <div className={`${styles.elli} ${type === 2 ? styles.match : ''}`}>{downStr}</div>
        </div>
    );
}

const CCDialog = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [processId, setProcessId] = useState('');
    const [ccList, setCcList] = useState([]);
    const [isOut, setIsOut] = useState(false);
    const [list, setList] = useState([]);
    const rowRef = useRef(null);
    const ccListRef = useRef(null);

    useEffect(() => {
        if (Array.isArray(ccList) && ccList.length > 0) {
            const temp = ccList.map(item => {
                return {
                    type: item.type,
                    id: generateId(),
                    name: item.name,
                    contact: item.email || item.phone,
                    company: item.company,
                    trueCompany: item.company,
                    options: {
                        name: [],
                        contact: [],
                        company: [],
                    },
                    contactErr: '',
                    nameErr: false,
                    companyErr: false,
                    searchLoading: -1,
                };
            });
            ccListRef.current = JSON.stringify(temp);
            setList(temp);
        } else {
            ccListRef.current = JSON.stringify([]);
            setList([]);
        }
    }, [ccList]);

    const open = ({currProcessId, isOut}) => {
        setIsOut(isOut);
        setProcessId(currProcessId);
        getCcList(currProcessId);
        setVisible(true);
    }

    const close = () => setVisible(false);

    const getCcList = (processId) => {
        return getProcessCCInfo({processId}).then(data => {
            data.forEach(item => {
                item.type = item.company ? UserType.org : UserType.person;
            })
            setCcList(data);
        })
    }

    useImperativeHandle(ref, () => ({
        open
    }));

    const addItem = (type) => {
        if (list.length > 0) {
            if (!checkList()) {
                return;
            }
        }
        const id = generateId();
        const item = {
            type,
            id,
            name: '',
            contact: '',
            company: '', // 输入的企业名称
            trueCompany: '', // 选择的企业名称
            options: {
                name: [],
                contact: [],
                company: [],
            },
            contactErr: '',
            nameErr: false,
            companyErr: false,
            searchLoading: -1,
        };
        setList([...list, item]);
    };

    const deleteItem = (id) => {
        setList(list.filter(item => item.id !== id));
    };

    const resolveFormData = (data, originData) => {
        data.forEach(item => {
            const originItem = originData.find(it => {
                const accountEqual = ((it.email && it.email === item.email) || (it.phone && it.phone === item.phone));
                if(accountEqual && it.company === item.company) {
                    return true;
                } else {
                    return false;
                }
            })
            if (originItem) {
                item.id = originItem.id;
            }
            if (item.type === 1) {
                delete item.company;
                item.type = 0;
            } else {
                item.type = 1;
            }
        })
        return data;
    }

    const onSave = () => {
        if (!checkList()) {
            return;
        }
        const temp = list.map(item => {
            return {
                type: item.type,
                name: item.name,
                email: item.contact.includes('@') ? item.contact : '',
                phone: item.contact.includes('@') ? '' : item.contact,
                company: item.trueCompany,
            };
        });
        const params = resolveFormData(temp, ccList);
        updateProcessCCInfo({processId, data: params})
        .then(() => {
            successMessage('修改抄送人成功');
            close();
        }).catch(() => {
            errorMessage('修改抄送人失败，请重试');
        })
    };

    const onCancel = () => {
        const listStr = JSON.stringify(list);
        if (ccListRef.current !== listStr) {
            Modal.confirm({
                title: '提示',
                content: '确定取消编辑文档抄送人吗？',
                zIndex: 99999,
                onOk: () => {
                    close(undefined);
                }
            })
        } else {
            close(undefined);
        }
    };

    // 统一验证全部记录合法性
    const checkList = () => {
        let res = true;
        const contactErrs = [];
        const temp = list.map((item, index) => {
            const noNameErr = changeName({ value: item.name, item, showError: true });
            const noContactErr = changeContact({ value: item.contact, item, showError: true });
            let noOrgNameErr = true;
            if (item.type === UserType.org) {
                noOrgNameErr = changeCompany({ value: item.trueCompany, item, showError: true });
            }
            if (!noNameErr || !noContactErr || !noOrgNameErr) {
                res = false;
                rowRef.current.scrollTo(0, index * 50);
                return item;
            }

            if (contactErrs.filter(it => it.contact === item.contact).length > 0) {
                res = false;
                item.contactErr = '邮箱/手机号不可重复';
                rowRef.current.scrollTo(0, index * 50);
            } else {
                contactErrs.push({ contact: item.contact, trueCompany: item.trueCompany });
            }

            return item;
        });
        setList(temp);
        return res;
    };

    // 搜索历史记录或企业查中企业名称
    const onSearch = (searchText, type, id) => {
        if (!searchText) {
            return;
        }
        const item = list.find(item => item.id === id);
        if (!item) {
            return;
        }
        debounce(() => {
            item.searchLoading = type;
            setList(list.map(i => i.id === id ? item : i));
            const newOptions = { ...item.options };
            getOptions(searchText, isOut, type).then(res => {
                newOptions[type === 2 ? 'company' : type === 1 ? 'contact' : 'name'] = res;
            }).catch(() => {
                newOptions[type === 2 ? 'company' : type === 1 ? 'contact' : 'name'] = [];
            }).finally(() => {
                item.options = newOptions;
                item.searchLoading = -1;
                setList(list.map(i => i.id === id ? item : i));
            });
        }, 500)();
    };

    // 选择查询到的结果
    const onSelect = (value, id) => {
        const res = JSON.parse(value);
        const { name, contact, company } = res;

        const item = list.find(item => item.id === id);
        if (!item) {
            return;
        }
        if (name) {
            changeName({ value: name, item });
        }
        if (contact) {
            changeContact({ value: contact, item });
        }
        if (isOut && company) {
            item.trueCompany = company;
            changeCompany({ value: company, item });
        }
        if (item) {
            setList(list.map(i => i.id === id ? item : i));
        }
    };

    // 验证姓名
    const changeName = ({ value, id, item, showError }) => {
        if (!item && id) {
            item = list.find(item => item.id === id);
        }
        if (!item) {
            return;
        }
        const hasErr = !value || value.length > 50 || value.length < 1;
        item.name = value;
        if (!hasErr || item.nameErr || showError) {
            item.nameErr = hasErr;
        }
        if (id) {
            setList(list.map(i => i.id === id ? item : i));
        }
        return !hasErr;
    };

    // 验证邮箱/手机号
    const changeContact = ({ value, id, item, showError }) => {
        if (!item && id) {
            item = list.find(item => item.id === id);
        }
        if (!item) {
            return;
        }

        let errStr = '';
        let hasErr = !value || value.length > 100 || value.length < 1;
        if (!hasErr) {
            if (!/^[a-zA-Z\d._-]+@[a-zA-Z\d_-]+(\.[a-zA-Z\d_-]+)+$/.test(value)) {
                if (!/^1[3-9]\d{9}$/.test(value)) {
                    hasErr = true;
                    errStr = '请输入正确的邮箱/手机号';
                }
            }
        } else {
            errStr = '请输入邮箱/手机号';
        }
        item.contact = value;
        if (!hasErr || item.contactErr || showError) {
            item.contactErr = errStr;
        }
        if (id) {
            setList(list.map(i => i.id === id ? item : i));
        }
        return !hasErr;
    };

    // 验证企业名称
    const changeCompany = ({ value, id, item, showError }) => {
        if (!item && id) {
            item = list.find(item => item.id === id);
        }
        if (!item) {
            return;
        }
        let hasErr = !value || value.length > 100 || value.length < 1;
        if (showError) {
            if (!hasErr) {
                hasErr = item.company !== item.trueCompany;
            }
        } else {
            item.company = value;
        }
        if (!hasErr || item.companyErr || showError) item.companyErr = hasErr;
        if (id) {
            setList(list.map(i => i.id === id ? item : i));
        }
        return !hasErr;
    };

    if (!visible) {
        return <></>
    }

    return (
        <ConfigProvider locale={locale}>
            <Modal title={'文档抄送人'}
                   open={true}
                   width={800}
                   centered
                   onOk={onSave}
                   onCancel={onCancel}>
                <div className={styles.CCContainer}>
                    <div>
                        <Space>
                            <Button onClick={() => addItem(UserType.person)} className={styles.btn}
                                    size='small' type='primary'
                                    icon={<PlusOutlined />}>{isOut ? '个人' : '抄送人'}</Button>
                            {isOut && <Button onClick={() => addItem(UserType.org)} className={styles.btn}
                                              size='small' type='primary'
                                              icon={<PlusOutlined />}>企业</Button>}
                        </Space>
                    </div>
                    {isOut && <Row gutter={[12, 0]} style={{ marginTop: '20px' }}>
                        <Col span={2}><b>类型</b></Col>
                        <Col span={4}><b>姓名</b></Col>
                        <Col span={8}><b>邮箱/手机号</b></Col>
                        <Col span={8}><b>企业全称</b></Col>
                        <Col span={2}><b>操作</b></Col>
                    </Row>}
                    {!isOut && <Row gutter={[12, 0]} style={{ marginTop: '20px' }}>
                        <Col span={10}><b>姓名</b></Col>
                        <Col span={12}><b>邮箱/手机号</b></Col>
                        <Col span={2}><b>操作</b></Col>
                    </Row>}
                    <Divider style={{ margin: '10px 0' }} />
                    {list.length < 1 && <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '163px',
                    }}>
                        <Avatar size={64} icon={<UserOutlined />} />
                        <p style={{ color: 'gray', marginTop: '10px' }}>暂无抄送人</p>
                    </div>}
                    {list.length > 0 &&
                        <Row ref={rowRef} gutter={[12, 0]} style={{ maxHeight: '163px', overflowY: 'auto' }}>
                            {list.map(item => {
                                return (<Fragment key={item.id}>
                                        {isOut && <Col span={2}>{item.type === UserType.org ? '企业' : '个人'}</Col>}
                                        <Col span={isOut ? 4 : 10}>
                                            <AutoComplete
                                                options={item.options.name}
                                                style={{ width: '100%' }}
                                                onSelect={val => onSelect(val, item.id)}
                                                onSearch={val => onSearch(val, 0, item.id)}
                                                onChange={value => changeName({ value, id: item.id })}
                                                dropdownClassName={styles.nameOptions}
                                                value={item.name}
                                            >
                                                <Input.Search size={'small'}
                                                              // maxLength={50}
                                                              placeholder={`姓名 * `}
                                                              allowClear={item.searchLoading !== 0}
                                                              loading={item.searchLoading === 0} />
                                            </AutoComplete>
                                            {item.nameErr && <span className={styles.error}>{`请输入正确的姓名`}</span>}
                                        </Col>
                                        <Col span={isOut ? 8 : 12}>
                                            <AutoComplete
                                                options={item.options.contact}
                                                style={{ width: '100%' }}
                                                onSelect={val => onSelect(val, item.id)}
                                                onSearch={val => onSearch(val, 1, item.id)}
                                                onChange={value => changeContact({ value, id: item.id })}
                                                dropdownClassName={styles.nameOptions}
                                                value={item.contact}
                                            >
                                                <Input.Search size={'small'}
                                                              // maxLength={100}
                                                              placeholder={`邮箱/手机号 *`}
                                                              allowClear={item.searchLoading !== 1}
                                                              loading={item.searchLoading === 1} />
                                            </AutoComplete>
                                            {item.contactErr && <span className={styles.error}>{item.contactErr}</span>}
                                        </Col>
                                        {isOut && <Col span={8}>
                                            {item.type === UserType.org && <AutoComplete
                                                options={item.options.company}
                                                style={{ width: '100%' }}
                                                onSelect={val => onSelect(val, item.id)}
                                                onSearch={val => onSearch(val, 2, item.id)}
                                                onChange={value => changeCompany({ value, id: item.id })}
                                                dropdownClassName={styles.nameOptions}
                                                value={item.company}
                                            >
                                                <Input.Search size={'small'}
                                                              // maxLength={100}
                                                              placeholder={`企业全称 *`}
                                                              allowClear={item.searchLoading !== 2}
                                                              loading={item.searchLoading === 2} />
                                            </AutoComplete>}
                                            {item.companyErr && <span
                                                className={styles.error}>{item.company ? '请从查询列表中选择企业全称' : '请输入企业全称'}</span>}
                                        </Col>}
                                        <Col span={2} style={{ fontSize: '18px' }}><DeleteOutlined
                                            onClick={() => deleteItem(item.id)} /></Col>
                                        <Divider style={{ margin: '10px 0' }} /></Fragment>
                                );
                            })}
                        </Row>}
                </div>
            </Modal>
        </ConfigProvider>
    );
})

export default CCDialog;