import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Button, ConfigProvider, Space, Table, Tag, Tooltip, Typography } from 'antd';
import EditPermission from './Edit';
import styles from './index.module.less';
import {getQueryVariable} from "../service";
import Search from "antd/es/input/Search";
import zhCN from "antd/es/locale/zh_CN";
import {closeESignCNDialogAPI} from "../../../request/phantom";
import {resizeDialog} from "../Model";
import AuthProvider from "../PhantomAuth/AuthProvider";
import {getEnterpriseUserPermissionList} from "../../../request/esign";
import {ERROR_ESIGN_ORG_PERMISSION_FAIL} from "../../../request/error_container";
import {errorMessage} from "../../components/Message";
import {goAccount} from "../../../request/user";

const { Text } = Typography;


export default function PhantomOrgAdmin() {
    const [isPreconditionMet, setIsPreconditionMet] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [items, setItems] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState('');

    const servData = useRef([]);

    const wardType = getQueryVariable('wardType') === '1' ? 1 : 0;
    const isOut = wardType === 0;
    const fetchData = useCallback(() => {
        setLoading(true);
        getEnterpriseUserPermissionList({wardType: isOut ? 0 : 1}).then((res) => {
            if (res && Array.isArray(res)) {
                const data = res.map((item) => {
                    return {
                        ...item,
                        key: item.userId
                    };
                });
                servData.current = data;
                setData(data);
                onSearch(keyword);
            }
        }).catch(e => {
            if (e === ERROR_ESIGN_ORG_PERMISSION_FAIL) {
                errorMessage('当前账户无操作权限，请联系管理员。');
            }
        })
            .finally(() => {
                setLoading(false);
            })

    }, [isOut, keyword]);

    useEffect(() => {
        if (isPreconditionMet) {
            resizeDialog({ width: 820, height: 630 });
            fetchData();
        }
    }, [isPreconditionMet, fetchData]);

    const columns = [
        {
            title: '用户名',
            dataIndex: 'userName',
            width: 140,
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            width: 160,
            render: (_, { email }) => <Tooltip style={{width: '100%'}} title={email}><Text
                style={{ maxWidth: '100%', }}
                ellipsis={true}
            >
                {email}
            </Text></Tooltip>,
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
            width: 120,
            render: (_, { mobile }) => (
                <span>{mobile.replace('+86', '')}</span>
            ),
        },
        {
            title: '已授权',
            key: 'permissions',
            dataIndex: 'permissions',
            render: (_, { permissions }) => (
                <>
                    {permissions.map((permission) => {
                        const text = permission === 2 ? '印章记录' : '签署记录';
                        const color = permission === 2? '#188711' : '#1664F4';
                        return (
                            <Tag style={{color, borderColor: color}} key={text}>
                                {text}
                            </Tag>
                        );
                    })}
                </>
            )
        },
        {
            title: '操作',
            key: 'action',
            width: 80,
            render: (_, record) => (
                <Space size="middle">
                    <a style={{color: '#a236b2'}} onClick={() => onShowEdit(record)} href={'#'}>编辑授权</a>
                </Space>
            ),
        },
    ];

    const onSearch = (value) => {
        setSelectedRowKeys([]);
        const fuzzyMatchIgnoreCase = (a, b) => {
            const lowerA = a.toLowerCase();
            const lowerB = b.toLowerCase();
            return lowerA.includes(lowerB);
        }
        const temp = !value ? servData.current : servData.current.filter(item => fuzzyMatchIgnoreCase(item.userName, value) || fuzzyMatchIgnoreCase(item.email, value) || fuzzyMatchIgnoreCase(item.mobile, value));
        setKeyword(value);
        setData(temp);
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const onShowEdit = (item) => {
        let items = [];
        if (!item) {
            items = data.filter(item => selectedRowKeys.includes(item.key));
        } else {
            items.push(item)
        }
        setShowEdit(true);
        setItems(items);
    }

    const onEditSave = (isOk) => {
        setShowEdit(false);
        isOk && fetchData();
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            {
                key: 'none',
                text: '全部取消',
                onSelect: () => {
                    setSelectedRowKeys([]);
                },
            },
        ],
    };

    const endProcessCallback = ({ success, setName }) => {
        if (success) {
            setIsPreconditionMet(true);
        } else {
            if (setName) {
                goAccount();
            }
            closeESignCNDialogAPI();
        }
    }

    return (
        <AuthProvider
            isOut={isOut}
            endProcessCallback={endProcessCallback}
        >
            <ConfigProvider locale={ zhCN }>
                <div className={styles.container}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Search placeholder="输入用户名/邮箱/手机号搜索" allowClear onSearch={onSearch} style={{ width: 300, height: 24 }} />
                        <Button type="primary" disabled={selectedRowKeys.length < 1} onClick={() => onShowEdit()} >
                            批量授权
                        </Button>
                    </div>
                    <Table pagination={false}
                           scroll={{ y: 455 }}
                           className={styles.table}
                           showHeader
                           rowSelection={rowSelection}
                           columns={columns}
                           size="small"
                           loading={loading}
                           dataSource={data} />
                    {showEdit && <EditPermission save={onEditSave} items={items} isOut={isOut} ></EditPermission>}
                </div>
            </ConfigProvider>
        </AuthProvider>
    )
}
